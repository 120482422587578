import WSController, { ClientWSCommands } from "../Components/WSController";

/**
 * @type {string[]}
 */
export const colorList = [
    "#8FBC8F", 
    "#AFEEEE", 
    "#8B008B", 
    "#00BFFF", 
    "#FAEBD7", 
    "#00FA9A", 
    "#DA70D6", 
    "#B8860B",
    "#CD5C5C",
    "#FF7F50",
    "#B22222",
    "#008B8B",
    "#FFFF00",
    "#FFE4B5"
];

let colorUseIndex = 0;

/**
 * @private
 * @brief Running number for listener IDs
 */
let rnbe = 0;

/**
 * @brief Device type enumeration
 */
 export const DeviceType = {
    MASTER: 0,
    SLAVE: 1
};


/**
 * @brief Template for device data format
 */
export const BaseData = {
    Addr:   0, 
    Error:  0,
    T1:     0, 
    T2:     0, 
    RPM:    0
}
/**
 * @brief Template for Master data format
 */
export const MasterData = {
    Uptime:     0,
    UVCTime:    0,
    Boxcount:   0,
    UVCOn:      false,
    ExtVolt:    0,
    rssi:       0
}

/**
 * @brief Template for Slave data format
 */
export const SlaveData = {
    U1:     0,
    U2:     0,
    U3:     0,
    U4:     0,
    I1:     0,
    I2:     0,
    I3:     0,
    I4:     0
}

export const DeviceInfo = {
    
    id:       0, // Key
    deviceid: "",
    name:     "",
    type:     DeviceType.MASTER,
    master:       "", // deviceID
    coordinates: {
        latitude:       0,
        longitude:      0
    },
    address:        0,
    note:      "",
    /**
     * @type {string|null}
     */
    installed: null,
    /**
     * @type {string|undefined}
     */
    remoteAddress: undefined
}


export default class Device {

    /**
     * @brief Array of all devices
     * @type {Device[]}
     */
    static devices = [];

    /**
     * @callback onDeviceUpdate
     * @param {Device} device
     */

    /**
     * @brief Device update listeners - called when new device data is received
     * @private
     * @type {{fun: onDeviceUpdate, id: number}[]}
     */
    static deviceUpdateListeners = [];

    /**
     * @brief Device information
     */
    deviceInfo = {
        ...DeviceInfo
    }

    /**
     * @brief Device data
     * @type {BaseData}
     */
    deviceData = {
        ...BaseData
    }
    /**
     * @brief Master data
     * @type {MasterData}
     */
    masterData = {
        ...MasterData
    }

    /**
     * @brief Slave data
     * @type {SlaveData}
     */
    slaveData = {
        ...SlaveData
    }

    /**
     * @brief Device type, master or slave
     */
    deviceType = DeviceType.MASTER;

    /**
     * @type {Device}
     * @brief Master device - null if deviceType is MASTER
     */
    master = null;

    /**
     * @type {Device[]}
     * @brief Slave devices - null if 
     */
    slaves = [];

    /**
     * @brief Is the device connected and active
     */
    active = false;

    /**
     * @type {{id: number, name: string}[]}
     */
    organizations = [];

    firmware = null;

    updating = false;
    
    /**
     * @brief WiFi reconnect count
     */
    wificnt = 0;

    /**
     * @brief Channel cutoff voltage
     */
    chCutoff = 0;

    miscData = {
        Boxcount: {
            day: 0,
            week: 0,
            month: 0
        }
    }

    color = "#FFF";

    channelDefinitions = {
        types: [0, 0, 0, 0],
        bars: [0, 0, 0, 0],
        min: [0, 0, 0, 0],
        max: [0, 0, 0, 0]
    };

    majorVersion = 0;
    minorVersion = 0;


    constructor(deviceInfo) {
        
        this.deviceInfo = { ...this.deviceInfo, ...deviceInfo };

        this.getHistory = this.getHistory.bind(this);

        this.getOrganizations = this.getOrganizations.bind(this);
        this.getUVCTime = this.getUVCTime.bind(this);
        this.sendNotes = this.sendNotes.bind(this);
        this.setLocation = this.setLocation.bind(this);

        if(this.deviceInfo.type === DeviceType.MASTER) {
            this.color = colorList[colorUseIndex++];
            if(colorUseIndex >= colorList.length) {
                colorUseIndex = 0;
            }
        }
        
        Device.devices.push(this);

        if(this.deviceInfo.type === DeviceType.MASTER) {
            this.getOrganizations(e => {
                
            });
        }
    }

    /**
     * @brief Parses a data message and sets new values
     * @param {object} data BaseData
     * @param {object} extraData MasterData|SlaveData
     */
    parseData (data, extraData) {
        this.deviceData = { ...this.deviceData, ...data };
        if(this.deviceInfo.type === DeviceType.MASTER) {
            // Handle extraData as MasterData
            this.masterData = { ...this.masterData, ...extraData };
        }
        else {
            // Handle extraData as SlaveData
            this.slaveData = { ...this.slaveData, ...extraData };
        }
        
        Device.deviceUpdateListeners.map((d) => {
            d.fun(this);
            return null;
        });
    }

    /**
     * @brief Fetches history for this device
     * @param {Date} from 
     * @param {Date} to 
     * @param {function} cb 
     */
    getHistory (from, to, cb, limit = 10000) {
        WSController.request({
            cmd: ClientWSCommands.GETHISTORY,
            devices: [this.deviceInfo.deviceid],
            from: from.toISOString(),
            to: to.toISOString(),
            limit: limit
        }, cb);
    }

    getBoxCount (cb) {
        WSController.request({
            cmd: ClientWSCommands.GETBOXCOUNT,
            device: this.deviceInfo.deviceid
        }, cb);
    }

    /**
     * @brief Fetches logs for this device
     * @param {Date} from 
     * @param {Date} to 
     * @param {Function} cb 
     */
    getLogs (from, to, cb) {
        WSController.request({
            cmd: ClientWSCommands.GETLOGS,
            device: this.deviceInfo.deviceid,
            from: from.toISOString(),
            to: to.toISOString()
        }, cb);
    }

    /**
     * @brief Gets device organizations
     * @param {Function} cb Callback with organizations
     */
    getOrganizations (cb) {
        WSController.request({
            cmd: ClientWSCommands.GETDEVORGS,
            device: this.deviceInfo.deviceid
        }, (msg) => {
            if(msg.status) {
                this.organizations = msg.organizations;
                cb(this.organizations);
            }
            else {
                cb([]);
            }
        });
    }

    /**
     * @brief Gets UVC time from the server
     * @param {Function} cb 
     */
    getUVCTime (cb) {
        let ndate = new Date();
        ndate.setDate(ndate.getDate() - 7);
        WSController.request({
            cmd: ClientWSCommands.GETUVCTIME,
            device: this.deviceInfo.deviceid,
            from: ndate.toISOString(),
            to: new Date().toISOString()
        }, (msg) => {
            if(!msg.status) {
                cb(null);
            }
            else {
                cb(msg.time);
            }   
        })
    }

    /**
     * @brief Firmware upgrade
     * @param {string} firmware 
     */
    upgradeDevice (firmware) {
        WSController.request({
            cmd: ClientWSCommands.UPDATEDEV,
            device: this.deviceInfo.deviceid,
            firmwareId: firmware
        }, (data) => {
            if(data.status) {
                // OK
            }
            else {
                // FAIL
            }
        });
    }

    /**
     * @brief Sends notes to the server
     */
    sendNotes () {
        WSController.request({
            cmd: ClientWSCommands.SETDEVNOTE,
            device: this.deviceInfo.deviceid,
            note: this.deviceInfo.note
        }, (data) => {
            if(data.status) {
                // OK
            }
            else {
                // FAIL
            }
        });
    }

    /**
     * @brief Updates the listeners for this device
     */
    updateListeners () {
        Device.deviceUpdateListeners.map((d) => {
            d.fun(this);
            return null;
        });
    }

    /**
     * @brief Sets the channel definitions
     * @param {{types: number[], bars: number[], min: number[], max: number[]}} channels 
     * @param {number} chCutoff 
     * 
     */
    setChannelDefs (channels, chCutoff) {
        this.channelDefinitions = channels;
        this.master.chCutoff = chCutoff;

        WSController.request({
            cmd: ClientWSCommands.SETDEVCHANNELS,
            device: this.deviceInfo.type === DeviceType.MASTER ? this.deviceInfo.deviceid : this.master.deviceInfo.deviceid,
            slaves: [
                {
                    address: this.deviceInfo.address,
                    channels: channels
                }
            ],
            chcutoff: this.master.chCutoff
        }, data => {
            alert("Channel setup sent");
        });
    }

    setLocation (location) {
        WSController.request({
            cmd: ClientWSCommands.SETDEVLOCATION,
            device: this.deviceInfo.type === DeviceType.MASTER ? this.deviceInfo.deviceid : this.master.deviceInfo.deviceid,
            location: {
                lat: location.lat,
                lng: location.lng
            }
        }, data => {
            alert("Device location updated");
        });
    }

    resetMaster () {
        WSController.request({
            cmd: ClientWSCommands.RESETMASTER,
            device: this.deviceInfo.type === DeviceType.MASTER ? this.deviceInfo.deviceid : this.master.deviceInfo.deviceid
        }, data => {
            alert("Master reset");
        });
    }

    resetSlave (slave) {
        WSController.request({
            cmd: ClientWSCommands.RESETSLAVE,
            device: this.deviceInfo.type === DeviceType.MASTER ? this.deviceInfo.deviceid : this.master.deviceInfo.deviceid,
            slave: slave
        }, data => {
            alert("Slave reset");
        });
    }

    resetSystem () {
        WSController.request({
            cmd: ClientWSCommands.RESETSYSTEM,
            device: this.deviceInfo.type === DeviceType.MASTER ? this.deviceInfo.deviceid : this.master.deviceInfo.deviceid
        }, data => {
            alert("System reset");
        });
    }

    /**
     * @brief Check version
     * @param {string} major 
     * @param {string} minor 
     * @returns true if the version matches or is higher than given
     */
    checkVersion (major, minor) {
        return (this.majorVersion > major || (this.majorVersion === major && this.minorVersion >= minor));
    }

    /**
     * 
     * @param {number} slave 
     * @param {string} firmware 
     */
    updateLC (slave, firmware) {
        WSController.request({
            cmd: ClientWSCommands.UPDATELC,
            device: this.deviceInfo.type === DeviceType.MASTER ? this.deviceInfo.deviceid : this.master.deviceInfo.deviceid,
            slave: slave,
            firmware: firmware
        }, data => {
            alert("LC " + slave + " update started...");
        });
    }

    /**
     * 
     * @param {Device[]} devices 
     */
    static getAllMiscData (devices) {
        WSController.request({
            cmd: ClientWSCommands.GETMISCINFO,
            devices: devices.map(e => e.deviceInfo.deviceid)
        }, (msg) => {
            
            if(msg.status) {
                for(let data of msg.data) {
                    if(data.data !== null) {
                        let dev = Device.findDevice(data.device);
                        if(dev) {
                            dev.miscData = data.data;
                        }
                    }
                }
                Device.update();
            }
            else {

            }
        });
    }

    /**
     * @brief Fetches history for devices
     * @param {Device[]} devices
     * @param {Date} from 
     * @param {Date} to 
     * @param {function} cb 
     */
    static getAllHistory (devices, from, to, cb, limit = 10000) {
        WSController.request({
            cmd: ClientWSCommands.GETHISTORY,
            devices: devices.map(e => e.deviceInfo.deviceid),
            from: from.toISOString(),
            to: to.toISOString(),
            limit: limit
        }, cb);
    }

    /**
     * @brief Adds an update listener, must be removed before unmount
     * @param {onDeviceUpdate} fn 
     * @returns {number} Update listener ID
     */
    static addUpdateListener (fn) {
        Device.deviceUpdateListeners.push({fun: fn, id: rnbe});
        return rnbe++;
    }

    /**
     * @brief Removes an update listener
     * @param {number} listenerId 
     * @returns {boolean} True if removed
     */
    static removeUpdateListener (listenerId) {
        let i = Device.deviceUpdateListeners.find((e) => e.id === listenerId);
        if(i < 0) {
            return false;
        }
        else {
            Device.deviceUpdateListeners.splice(i, 1);
            return true;
        }
    }

    /**
     * @brief Calls update listeners
     */
    static update () {
        Device.deviceUpdateListeners.map((d) => {
            d.fun(null);
            return null;
        });
    }

    /**
     * @brief Finds a device by device id
     * @param {string} deviceId 
     * @returns {Device} Device if found
     */
    static findDevice (deviceId) {
        return Device.devices.find((d) => d.deviceInfo.deviceid === deviceId);
    }

    static findDeviceId(id) {
        return Device.devices.find((e) => e.deviceInfo.id === id);
    }

    /**
     * @brief Finds all masters
     * @returns {Device[]} Devices
     */
     static findMasters () {
        return Device.devices.filter((d) => d.deviceInfo.type === DeviceType.MASTER);
    }
}