import React, { useRef } from 'react';
import ReactTooltip from 'react-tooltip';
import { FlexibleWidthXYPlot, LineSeries, XAxis, YAxis } from 'react-vis';
import { HeatmapSeries, XYPlot } from 'react-vis/dist';
import HeatMapTimeline from '../Components/HeatMapTimeline';
import Device from '../Misc/Device';
import { addZ, formatDate, formatDateTime, formatTime, legendItem } from '../Misc/Helpers';
import LCSS from './LCSS';

/**
 * @type {{vName: string, dName: string, tooltip: string, decimals: number, unit: string}[]}
 */
 const dataHeaders = [
    { vName: "name", dName: "Name", tooltip: "", decimals: -1, unit: "" },
    { vName: "Error", dName: "Error", tooltip: "", decimals: 0, unit: "" },
    { vName: "Uptime", dName: "Uptime", tooltip: "Uptime", decimals: 0, unit: "" },
    { vName: "UVCTime", dName: "UVC time", tooltip: "UVC time", decimals: 0, unit: "" },
    { vName: "UVCTimeP", dName: "UVC %", tooltip: "UVC %", decimals: 1, unit: "%" },
    { vName: "Boxcount", dName: "Box count", tooltip: "", decimals: 0, unit: "" },
    { vName: "ExtVolt", dName: "Ext voltage", tooltip: "", decimals: 1, unit: "V" },
    { vName: "T1", dName: "Temp LCDIO", tooltip: "", decimals: 0, unit: "°C" },
    { vName: "T2", dName: "Temp cabin", tooltip: "", decimals: 0, unit: "°C" },
    { vName: "RPM", dName: "Fan RPM", tooltip: "", decimals: 0, unit: "" }
]

/**
 * @type {{vName: string, dName: string, tooltip: string, decimals: number, unit: string}[]}
 */
 const dataHeadersMobile = [
    { vName: "name", dName: "Name", tooltip: "", decimals: -1, unit: "" },
    { vName: "Error", dName: "Error", tooltip: "", decimals: 0, unit: "" },
    { vName: "Uptime", dName: "Uptime", tooltip: "Uptime", decimals: 0, unit: "" },
    { vName: "UVCTime", dName: "UVC time", tooltip: "UVC time", decimals: 0, unit: "" },
    { vName: "Boxcount", dName: "Box count", tooltip: "", decimals: 0, unit: "" },
    { vName: "T1", dName: "Temp LCDIO", tooltip: "", decimals: 0, unit: "°C" },
    { vName: "T2", dName: "Temp cabin", tooltip: "", decimals: 0, unit: "°C" }
]

/**
 * @brief Formats a data field
 * @param {number|string|undefined|null} value 
 * @param {{vName: string, dName: string, tooltip: string, decimals: number, unit: string}} field 
 */
 const FormatDataField = (value, field) => {
    if(value === undefined) {
        return "";
    }
    if(value === null) {
        return "-" + field.unit;
    }

    if(field.vName === "Uptime") {
        return formatTime(value);
    }
    if(field.vName === "UVCTime") {
        return formatTime(value);
    }


    if(field.decimals >= 0) {
        return value.toFixed(field.decimals) + field.unit;
    }

    // Default string return
    return value.toString();
}


export class SystemsOverview extends React.Component {

    state = {
        uvcTimeGraphData: [],

        graphBuilt: false,

        uvcTimeGraphRows: 0,
        /**
         * @type {Device[]}
         */
        devices: []
    }

    /**
     * @brief Device update listener ID
     * @private
     */
    _devUpdateListener = -1;

    contDiv = null;

    buildingGraph = false;

    constructor (props) {
        super(props);
        this.onDeviceUpdate = this.onDeviceUpdate.bind(this);
        this.uvcTimeGraph = this.uvcTimeGraph.bind(this);
        this.buildGraph = this.buildGraph.bind(this);
        this.contDiv = React.createRef();
        this.buildingGraph = false;
        this.state.graphBuilt = false;
    }

    /**
     * @brief Device update callback
     * @param {Device} device 
     */
    onDeviceUpdate (device) {
        this.state.devices = Device.devices;
        this.setState({devices: Device.devices});
        this.buildGraph();
    }

    componentDidMount () {
        this._devUpdateListener = Device.addUpdateListener(this.onDeviceUpdate);
        this.state.devices = Device.devices;
        this.buildingGraph = false;
        this.setState({graphBuilt: false, devices: Device.devices});
        if(Device.devices.length > 0) {
            this.buildGraph();
        }
    }

    componentWillUnmount () {
        if(!Device.removeUpdateListener(this._devUpdateListener)) {
            console.error("Failed to remove update listener!");
        }
    }

    buildGraph () {
        if(this.state.graphBuilt || this.buildingGraph)
            return;

        let dateFrom = new Date();
        dateFrom.setDate(dateFrom.getDate() - 3);
        
        let dataArr = [];
        this.fetchedMax = Device.findMasters().length;
        this.fetchedCount = 0;
        this.buildingGraph = true;
        let _ei = 0;

        for(let mstr of Device.findMasters()) {
            const ei = _ei;
            mstr.getBoxCount((data) => {
                console.log(data);
                let lt = 0;
                let g = 0;
                let startBC = 0;


                if(data.data.length > 0) {
                    
                    startBC = data.data[0].boxCount;
                    lt = Math.floor(data.data[0].time/3600);
                }
                let ts = 0;
                let sampleCnt = 0;
                for(let i = 0; i < data.data.length; i++) {
                    ts = Math.floor(data.data[i].time/3600);
                    if(ts !== lt) {
                        dataArr.push({x: Math.floor(lt * 1000 * 3600), y: ei, value: data.data[i].boxCount - startBC});
                        g++;
                        startBC = data.data[i].boxCount;
                        sampleCnt = 0;
                        lt = ts;
                    }
                    else {
                        sampleCnt++;
                    }
                }
                if(sampleCnt > 0) {
                    dataArr.push({x: Math.floor(lt * 1000 * 3600), y: ei, value: data.data[data.data.length - 1].boxCount - startBC});
                }
                this.fetchedCount++;
                if(this.fetchedCount >= this.fetchedMax) {
                    this.setState({uvcTimeGraphData: dataArr, graphBuilt: true});
                    console.log("Graph built!");
                }

            })
            _ei++;
        }
    }

    uvcTimeGraph (props) {

        const mobilePortrait = window.innerWidth < 768;
        
        return <div>
        <p style={{margin: 2, padding: 2, marginLeft: 15, fontWeight: 'bold', fontSize: '0.7em', color: '#777'}}>Box count by hour</p>
            <HeatMapTimeline width={props.width} 
                data={this.state.uvcTimeGraphData} 
                colorMin={{r: 215, g: 215, b: 215}} 
                colorMax={{r: 59, g: 160, b: 247}}
                margin={mobilePortrait ? 0 : 5}
                headers={Device.findMasters().map(e => e.deviceInfo.name)} />
            <div>
                {
                    false &&
                    Device.findMasters().map((e) => {
                        return legendItem(e.color, e.deviceInfo.name)
                    })
                }
            </div>
        </div>
    }

    render () {

        let headers = dataHeaders;
        const mobilePortrait = window.innerWidth < 768;

        if(mobilePortrait) {
            headers = dataHeadersMobile;
        }

        return <div className="device-container">
                <ReactTooltip
                place='top'
                effect='solid' 
                multiline={true}
                />
                <div className="device-box device-box-active">
                <h1 className={'device-box-title'} style={{textAlign: 'center', margin: 'auto'}}>{"Systems"}</h1>
                <div ref={this.contDiv}>
                    <table className="data-table" style={{width: '100%'}}>
                        <tbody>
                            <tr>
                                {
                                    headers.map((e, i) => {
                                        return <th key={"dh-" + i}>{e.dName}</th>
                                    })
                                }
                            </tr>
                            
                                {
                                    
                                    Device.findMasters().map((e, i) => {
                                        
                                        let err = LCSS.logCodes.find(h => h.code === e.deviceData.Error);
                                        let haserror = false;
                                        if(e.deviceData.Error) {
                                            haserror = true;
                                        }
                                        for(let slv of e.slaves) {
                                            if(slv.deviceData.Error !== 0) {
                                                haserror = true;
                                            }
                                        }
                                        return (<tr key={"ddv-" + i}>
                                            {
                                                headers.map((x, z) => {

                                                    let cls = "";
                                                    if(e.active && e.masterData.UVCOn) {
                                                        cls += "uvc-on";
                                                    }
                                                    
                                                    if(haserror) {
                                                        cls += "device-error";
                                                    }
                                                    if(!e.active) {
                                                        cls = "device-inactive";
                                                    }
                                                    if(x.vName === 'Error') {

                                                        let dtip = "Fetching errors...";
                                                        if(err) {
                                                            if(!haserror) {
                                                                // ERR_NO_ERRORS
                                                                dtip = err.name + "<br/>" + err.description;
                                                            }
                                                            else {
                                                                if(err.code !== 0) {
                                                                    dtip = "LCDIO: " + err.name + "<br/>" + err.description;
                                                                }
                                                                else {
                                                                    dtip = "";
                                                                }
                                                                for(let slv of e.slaves) {
                                                                    if(slv.deviceData.Error !== 0) {
                                                                        let slvcode = LCSS.logCodes.find(h => h.code === slv.deviceData.Error);
                                                                        if(slvcode) {
                                                                            dtip += "<br/>";
                                                                            dtip += "LC " + slv.deviceInfo.address + ": " + slvcode.name + "<br/>" + slvcode.description;
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                        }
                                                        return (
                                                            <td className={cls} key={"dv-" + z} data-tip={dtip}>
                                                                {FormatDataField(e.deviceData[x.vName], x)}
                                                            </td>
                                                        );    
                                                    }
                                                    else if(x.vName === 'Boxcount') {
                                                        let dtip = "Fetching data...";
                                                        dtip = `Day: ${e.miscData.Boxcount.day}<br/>Week: ${e.miscData.Boxcount.week}<br/>Month: ${e.miscData.Boxcount.month}<br/>`;
                                                        return (
                                                            <td className={cls} key={"dv-" + z} data-tip={dtip}>
                                                                {FormatDataField(e.masterData[x.vName], x)}
                                                            </td>
                                                        );   
                                                    }
                                                    
                                                    if(x.vName === "name") {
                                                        return <td className={cls} key={"dv-" + z}>{FormatDataField(e.deviceInfo[x.vName], x)}</td>
                                                    }
                                                    else if(x.vName === "UVCTimeP") {
                                                        if(e.deviceInfo.installed !== null) {
                                                            const installTime = new Date(e.deviceInfo.installed).getTime()/1000;
                                                            const timeSinceStart = (new Date().getTime()/1000) - installTime;
                                                            let dtip = `Since ${formatDate(new Date(e.deviceInfo.installed), 'fi')}`;
                                                            return <td className={cls} key={"dv-" + z} data-tip={dtip}>{FormatDataField(e.masterData.UVCTime / timeSinceStart * 100, x)}</td>
                                                        }
                                                        else {
                                                            let dtip = `Not installed`;
                                                            return <td className={cls} key={"dv-" + z} data-tip={dtip}>-%</td>

                                                        }
                                                    }
                                                    else {
                                                        return <td className={cls} key={"dv-" + z}>{FormatDataField({...e.deviceData, ...e.masterData}[x.vName], x)}</td>
                                                    }
                                                })
                                            }
                                            </tr>
                                        )
                                    })
                                }
                        </tbody>
                    </table>
                    {
                        (this.state.uvcTimeGraphData.length > 0 && this.contDiv.current) &&
                        <this.uvcTimeGraph width={this.contDiv.current.getBoundingClientRect().width}/>
                    }
                    {
                        this.state.uvcTimeGraphData.length <= 0 &&
                        <div style={{textAlign: 'center', width: '100%', margin: 40}}>Loading...</div>
                    }
                </div>
            </div>
            
        </div>
    }
}