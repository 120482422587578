import React from "react";
import WSController, { ClientWSCommands } from "../Components/WSController";
import LCSS, { UserLevel } from "./LCSS";


export default class ProfileView extends React.Component {

    state = {
        userInfo: {
            username: "",
            displayName: "",
            organization: "",
            level: "",
            /**
             * @type {{id: number, number: string, enabled: boolean, primary: boolean}[]}
             */
            numbers: [],
            /**
             * @type {{id: number, email: string, enabled: boolean, primary: boolean}[]}
             */
            emails: [],
        },
        password: {
            oldpw: "",
            newpw: ""
        }
    }

    constructor (props) {
        super(props);

        this.state.userInfo = { ...this.state.userInfo, ...LCSS.account };

        this.saveUserData = this.saveUserData.bind(this);
        this.addNumber = this.addNumber.bind(this);
        this.removeNumber = this.removeNumber.bind(this);
        this.changePassword = this.changePassword.bind(this);
    }

    componentDidMount () {

        // Fetch phone numbers
        WSController.request({
            cmd: ClientWSCommands.GETPHONES
        }, data => {
            if(data.status) {
                this.setState({userInfo: {
                    ...this.state.userInfo,
                    numbers: data.numbers
                }});
            }
        });

        // Fetch emails
        WSController.request({
            cmd: ClientWSCommands.GETEMAILS
        }, data => {
            if(data.status) {
                this.setState({userInfo: {
                    ...this.state.userInfo,
                    emails: data.emails
                }});
            }
        });

    }

    /**
     * @brief Saves user data
     */
    saveUserData () {
        WSController.request({
            cmd: ClientWSCommands.SAVEUSERDATA,
            account: {
                // You can add other fields here later...
                displayName: this.state.userInfo.displayName
            },
            numbers: this.state.userInfo.numbers,
            emails: this.state.userInfo.emails
        }, (ok) => {
            // TODO: fetch user data
            if(ok) {
                alert("User data saved!")
            }
            else {
                alert("Failed to save user data");
            }
        });
    }

    /**
     * @brief Changes the password
     */
    changePassword () {
        WSController.request({
            cmd: ClientWSCommands.CHANGEPASS,
            oldpw: this.state.password.oldpw,
            newpw: this.state.password.newpw
        }, (ok) => {
            if(ok) {
                alert("Password was changed")
            }
            else {
                alert("Failed to change password");
            }
        });
    }

    /**
     * @brief Callback for profile field change
     * @param {string} form 
     * @param {string} field 
     * @param {Event} ev
     */
     profileFieldChanged (form, field, ev) {

        if(form === 'userInfo') {
            let st = this.state.userInfo;
            st[field] = ev.target.value;
            this.setState({userInfo: st});
        }
        else if(form === 'password') {
            let st = this.state.password;
            st[field] = ev.target.value;
            this.setState({password: st});
        }
    }

    removeNumber (index) {
        let nst = this.state.userInfo.numbers;
        if(nst[index].primary) {
            // Change primary
            if(nst.length > 0) {
                nst[0].primary = true;
            }
        }
        nst.splice(index, 1);
        this.setState({
            userInfo: { 
                ...this.state.userInfo,
                numbers: nst 
            }
        });
    }

    addNumber () {
        let nn = this.state.userInfo.numbers;
        nn.push({
            id: -1,
            number: "",
            enabled: true,
            primary: nn.length === 0 ? true : false
        });
        this.setState({
            userInfo: {
                ...this.state.userInfo,
                numbers: nn
            }
        });
    }

    /**
     * @brief On profile phone number change
     * @param {string} field 
     * @param {number} index 
     * @param {Event} ev 
     */
    profileNumberChanged (field, index, ev) {
        let nb = this.state.userInfo.numbers;
        if(field === 'primary') {
            for(let e of nb) {
                e.primary = false;
            }
            nb[index].primary = true;

            // Set primary number field
            this.setState({userInfo: {
                ...this.state.userInfo,
                numbers: nb
            }});
            return;
        }
        else if(field === 'enabled') {
            // Bool
            nb[index][field] = !nb[index][field];
        }
        else {
            // String
            nb[index][field] = ev.target.value;
        }
        this.setState({userInfo: {
            ...this.state.userInfo,
            numbers: nb,
        }});

    }

    render () {
        return (
            <div className="dyn-box-view">
                <div style={{flexDirection: 'row', flexWrap: 'wrap', flex: 1, display: 'flex'}}>
                    <div className="dyn-box">
                        <h2 style={{borderBottom: '1px solid rgba(0, 0, 0, 0.4)'}}>User information</h2>
                        <table>
                            <tbody>
                                <tr>
                                    <td>Username</td><td>{this.state.userInfo.username}</td>
                                </tr>
                                <tr>
                                    <td>Display name</td>
                                    <td>
                                        <input type="text" value={this.state.userInfo.displayName} onChange={(ev) => this.profileFieldChanged('userInfo', 'displayName', ev)} />
                                    </td>
                                </tr>
                                <tr>
                                    <td>Organization</td><td>{this.state.userInfo.organization}</td>
                                </tr>
                                <tr>
                                    <td>User level</td><td>{Object.keys(UserLevel).find(c => UserLevel[c] === this.state.userInfo.level)}</td>
                                </tr>
                            </tbody>
                        </table>
                        <h3 style={{borderBottom: '1px solid rgba(0, 0, 0, 0.4)'}}>Phone numbers</h3>
                        <table>
                            <tbody>
                                <tr>
                                    <th>Number</th><th>Enabled</th><th>Primary</th><th>Remove</th>
                                </tr>
                                {
                                    this.state.userInfo.numbers.map((v, i) => {
                                        return <tr key={"user-numbers-" + i}>
                                            <td>
                                                <input type={"tel"} value={this.state.userInfo.numbers[i].number} onChange={(e) => {this.profileNumberChanged('number', i, e)}} />
                                            </td>
                                            <td>
                                                <input type={"checkbox"} checked={this.state.userInfo.numbers[i].enabled} onChange={(e) => {this.profileNumberChanged('enabled', i, e)}} />
                                            </td>
                                            <td>
                                                <input type={"radio"} name="userinfo-phone-primary" checked={v.primary} onChange={(e) => {this.profileNumberChanged('primary', i, e)}} />
                                            </td>
                                            <td>
                                                <span style={{color: 'red', cursor: 'pointer'}} onClick={() => { this.removeNumber(i) }}>x</span>
                                            </td>
                                        </tr>
                                    })
                                }
                                <tr>
                                    <td colSpan={4}><span style={{fontSize: '2em', color: 'green', fontWeight: 'bolder', cursor: 'pointer'}} onClick={() => { this.addNumber() }} >+</span></td>
                                </tr>
                            </tbody>
                        </table>
                        {
                            /*
                            // TODO:
                            <h3 style={{borderBottom: '1px solid rgba(0, 0, 0, 0.4)'}}>Emails</h3>
                            */
                        }


                        <table>
                            <tbody>
                                <tr>
                                    <td colSpan={2} style={{borderTop: '1px solid rgba(0, 0, 0, 0.4)'}}><input type="button" value="Save" onClick={this.saveUserData} /></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="dyn-box">
                        <h2 style={{borderBottom: '1px solid rgba(0, 0, 0, 0.4)'}}>Change password</h2>
                        <table>
                            <tbody>
                                <tr>
                                    <td>Old password</td>
                                    <td>
                                        <input type="password" value={this.state.password.oldpw} onChange={(ev) => this.profileFieldChanged('password', 'oldpw', ev)} />
                                    </td>
                                </tr>
                                <tr>
                                    <td>New password</td>
                                    <td>
                                        <input type="password" value={this.state.password.newpw} onChange={(ev) => this.profileFieldChanged('password', 'newpw', ev)} />
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan={2} style={{borderTop: '1px solid rgba(0, 0, 0, 0.4)'}}><input type="button" value="Save" onClick={this.changePassword} /></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        );
    }

}