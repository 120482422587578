import React from 'react';
import { Link } from 'react-router-dom';

/**
 * @typedef {object} props
 * @property {string} label
 * @property {string} link
 * @extends {Component<Props>}
 */
export default class SideBarButton extends React.Component {
    link = "/";

    constructor (props) {
        super(props);
        if(props.link !== null && props.link !== undefined) {
            this.link = props.link;
        }
    }

    render () {

        return (
            <Link to={this.link} className="sidebar-button">
                {this.props.children}
            </Link>
        );
    }

}