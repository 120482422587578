import React, { createRef } from 'react';
import { Redirect } from 'react-router';
import Device from '../Misc/Device';
import { formatTime } from '../Misc/Helpers';
import LCSS from '../Views/LCSS';
import WSController, { ClientWSCommands } from './WSController';
import ReactTooltip from 'react-tooltip';

import DescriptionIcon from '../assets/description.png';

//#region Header definitions
/**
 * @type {{vName: string, dName: string, tooltip: string, decimals: number, unit: string}[]}
 */
const dataHeadersAdmin = [
    { vName: "Addr", dName: "Address", tooltip: "", decimals: 0, unit: "" },
    { vName: "Error", dName: "Error", tooltip: "", decimals: 0, unit: "" },
    { vName: "T1", dName: "Temp LC", tooltip: "", decimals: 0, unit: "°C" },
    { vName: "T2", dName: "Temp cabin", tooltip: "", decimals: 0, unit: "°C" },
    { vName: "RPM", dName: "Fan RPM", tooltip: "", decimals: 0, unit: "" },
    { vName: "U1", dName: "U1", tooltip: "", decimals: 1, unit: "V" },
    { vName: "U2", dName: "U2", tooltip: "", decimals: 1, unit: "V" },
    { vName: "U3", dName: "U3", tooltip: "", decimals: 1, unit: "V" },
    { vName: "U4", dName: "U4", tooltip: "", decimals: 1, unit: "V" },
    { vName: "I1", dName: "I1", tooltip: "", decimals: 0, unit: "mA" },
    { vName: "I2", dName: "I2", tooltip: "", decimals: 0, unit: "mA" },
    { vName: "I3", dName: "I3", tooltip: "", decimals: 0, unit: "mA" },
    { vName: "I4", dName: "I4", tooltip: "", decimals: 0, unit: "mA" }
]
/**
 * @type {{vName: string, dName: string, tooltip: string, decimals: number, unit: string}[]}
 */
const masterDataHeadersAdmin = [
    { vName: "Addr", dName: "Address", tooltip: "", decimals: 0, unit: "" },
    { vName: "Error", dName: "Error", tooltip: "", decimals: 0, unit: "" },
    { vName: "Uptime", dName: "Uptime", tooltip: "Uptime", decimals: 0, unit: "" },
    { vName: "UVCTime", dName: "UVC time", tooltip: "UVC time", decimals: 0, unit: "" },
    { vName: "Boxcount", dName: "Box count", tooltip: "", decimals: 0, unit: "" },
    { vName: "ExtVolt", dName: "Ext voltage", tooltip: "", decimals: 1, unit: "V" },
    { vName: "T1", dName: "Temp LCDIO", tooltip: "", decimals: 0, unit: "°C" },
    { vName: "T2", dName: "Temp cabin", tooltip: "", decimals: 0, unit: "°C" },
    { vName: "RPM", dName: "Fan RPM", tooltip: "", decimals: 0, unit: "" }
]
/**
 * @type {{vName: string, dName: string, tooltip: string, decimals: number, unit: string}[]}
 */
 const dataHeadersAdminMobile = [
    { vName: "Addr", dName: "Addr", tooltip: "", decimals: 0, unit: "" },
    { vName: "Error", dName: "Err", tooltip: "", decimals: 0, unit: "" },
    { vName: "T1", dName: "Temp LC", tooltip: "", decimals: 0, unit: "°C" },
    { vName: "T2", dName: "Temp cabin", tooltip: "", decimals: 0, unit: "°C" },
]
/**
 * @type {{vName: string, dName: string, tooltip: string, decimals: number, unit: string}[]}
 */
const masterDataHeadersAdminMobile = [
    { vName: "Addr", dName: "Addr", tooltip: "", decimals: 0, unit: "" },
    { vName: "Error", dName: "Err", tooltip: "", decimals: 0, unit: "" },
    { vName: "Uptime", dName: "Uptime", tooltip: "Uptime", decimals: 0, unit: "" },
    { vName: "UVCTime", dName: "UVC time", tooltip: "UVC time", decimals: 0, unit: "" },
    { vName: "Boxcount", dName: "Box count", tooltip: "", decimals: 0, unit: "" },
    { vName: "T1", dName: "Temp LCDIO", tooltip: "", decimals: 0, unit: "°C" },
    { vName: "T2", dName: "Temp cabin", tooltip: "", decimals: 0, unit: "°C" },
]

//#endregion

/**
 * @brief Formats a data field
 * @param {number|string|undefined|null} value 
 * @param {{vName: string, dName: string, tooltip: string, decimals: number, unit: string}} field 
 */
const FormatDataField = (value, field) => {
    if(value === undefined) {
        return "";
    }
    if(value === null) {
        return "-" + field.unit;
    }

    if(field.vName === "Uptime") {
        return formatTime(value);
    }
    if(field.vName === "UVCTime") {
        return formatTime(value);
    }


    if(field.decimals >= 0) {
        return value.toFixed(field.decimals) + field.unit;
    }

    return null;
}

/**
 * @brief Box display element to show device data
 */
export default class DeviceBox extends React.Component {

    /**
     * @type {Device}
     */
    device = null;

    state = {
        editingName: false,
        tmpName: "",
        headers: dataHeadersAdmin,
        masterHeaders: masterDataHeadersAdmin,
        goToDeviceDetails: false,
        showNote: false,
        noteValue: ""
    }

    constructor (props) {
        super(props);
        if(props.device !== null && props.device !== undefined) {
            this.device = props.device;
            this.state.noteValue = this.device.deviceInfo.note;
        }

        this.state.tmpName = this.device.deviceInfo.name;
        this.state.goToDeviceDetails = false;

        this.onNameDoubleClick = this.onNameDoubleClick.bind(this);
        this.onNameValidate = this.onNameValidate.bind(this);
        this.onNameEdit = this.onNameEdit.bind(this);
        this.openDeviceDetails = this.openDeviceDetails.bind(this);
        
        this.adminView = this.adminView.bind(this);

        this.notefield = createRef();
    }

    componentDidMount () {
        
    }

    /**
     * @brief Callback for when name is double clicked
     * @param {Event} e 
     */
    onNameDoubleClick (e) {

        this.setState({editingName: true});
    }

    /**
     * @brief Callback when exiting name edit mode (blur event)
     */
    onNameValidate () {

        if(this.state.tmpName.length < 3) {
            // FAIL
            this.state.tmpName = this.device.deviceInfo.name;
        }
        else {
            this.device.deviceInfo.name = this.state.tmpName;
            WSController.send(JSON.stringify({
                cmd: ClientWSCommands.SETDEVICENAME,
                device: this.device.deviceInfo.deviceid,
                name: this.device.deviceInfo.name
            }));
        }

        this.setState({editingName: false});
    }

    /**
     * @brief Callback when name is edited
     * @param {Event} e 
     */
    onNameEdit (e) {
        this.setState({tmpName: e.target.value});
    }

    /**
     * @brief Opens device detail page
     * @param {Device} device 
     */
    openDeviceDetails (device) {
        LCSS.selectedDeviceID = device.deviceInfo.deviceid;
        localStorage.setItem("selectedDevice", LCSS.selectedDeviceID);
        this.setState({goToDeviceDetails: true});
    }
    
    /**
     * @brief View to display when logged in as admin level user
     */
    adminView () {
        let devstyle = "device-box ";
        if(!this.device.active) {
            devstyle += "device-box-inactive ";
        }
        else {
            devstyle += "device-box-active ";
            if(this.device.masterData.UVCOn) {
                devstyle += "device-box-uvc ";
            }
        }

        let firmware = LCSS.firmwares.images.find(e => this.device.firmware === e.id);
        let fwname = firmware ? firmware.name : "-";

        let masterHeaders = masterDataHeadersAdmin;
        let headers = dataHeadersAdmin;


        if(window.innerWidth <= 768) {
            headers = dataHeadersAdminMobile;
            masterHeaders = masterDataHeadersAdminMobile;

        }

        return (
            <div className={devstyle}>
                <h1 className={'device-box-title'} style={{textAlign: 'center', margin: 'auto', cursor: 'pointer'}} onClick={() => { this.openDeviceDetails(this.device); }}>
                    {this.device.deviceInfo.name}
                    {
                        this.device.updating &&
                        <span style={{marginLeft: '5px', fontSize: '0.5em'}}>
                            [Updating...]
                        </span>
                    }
                    <img 
                        src={DescriptionIcon} 
                        alt={"Notes"} 
                        style={{float: 'right', marginRight: 30, height: 32}} 
                        onClick={(e) => {e.stopPropagation(); this.setState({showNote: true})}} 
                        />
                </h1>
                    <div style={{position: 'relative', width: '100%'}}>
                    {
                        this.state.showNote &&
                        <div style={{
                                position: 'absolute', 
                                right: 0, 
                                top: 0, 
                                zIndex: 50000
                            }}>
                            <textarea 
                                ref={this.notefield}
                                onBlur={(e) => {this.setState({showNote: false}); this.device.deviceInfo.note = e.target.value; this.device.sendNotes();}}
                                rows={10} 
                                style={{
                                    border: '1px solid rgba(0, 0, 0, 0.3)', 
                                    borderRadius: 5,
                                    padding: 5,
                                    width: 200,
                                }}
                                autoFocus
                                onChange={(e) => {this.setState({noteValue: e.target.value});}}
                                value={this.state.noteValue}
                                >
                            </textarea>
                        </div>
                    }
                    </div>
                <p style={{fontSize: '0.5em'}}>{this.device.deviceInfo.deviceid} : {fwname}</p>
                <p style={{fontWeight: 'bold', paddingLeft: 20, marginBottom: 0}}>LCDIO</p>
                <table className="data-table" style={{width: window.innerWidth > 768 ? '70%' : '100%', tableLayout: 'auto'}}>
                    <tbody>
                        <tr>
                            {
                                masterHeaders.map((v, i) => {
                                    return (<th key={"MHEADERNAME_" + i}>{v.dName}</th>);
                                })
                            }
                        </tr>
                        <tr onClick={() => {this.openDeviceDetails(this.device)}} style={{cursor: 'pointer'}} className="device-box-select-row">
                            {
                                masterHeaders.map((v, i) => {
                                    let cls = "";
                                    if(this.device.deviceData.Error !== 0) {
                                        cls += "device-error";
                                    }
                                    if(v.vName === 'Error') {
                                        let err = LCSS.logCodes.find(e => e.code === this.device.deviceData.Error);
                                        let dtip = "Fetching errors...";
                                        if(err) {
                                            dtip = err.name + "<br/>" + err.description;
                                        }
                                        return (
                                            <td key={"MASTERCOL_" + i} data-tip={dtip} className={cls}>
                                                {FormatDataField({...this.device.deviceData, ...this.device.masterData}[v.vName], v)}
                                            </td>
                                        );    
                                    }
                                    return (
                                        <td key={"MASTERCOL_" + i} className={cls}>{FormatDataField({...this.device.deviceData, ...this.device.masterData}[v.vName], v)}</td>
                                    );
                                })
                            }
                        </tr>
                    </tbody>
                </table>
                <p style={{fontWeight: 'bold', paddingLeft: 20, marginBottom: 0, marginTop: 0}}>LC</p>
                <table className="data-table">
                    <tbody>
                        <tr>
                            {
                                headers.map((v, i) => {
                                    return (<th key={"HEADERNAME_" + i}>{v.dName}</th>);
                                })
                            }
                        </tr>
                        {
                            this.device.slaves.map((v, i) => {
                                let cls = "";
                                if(v.deviceData.Error !== 0) {
                                    cls += "device-error";
                                }
                                return (<tr key={"DEVICEROW_" + i} onClick={() => {this.openDeviceDetails(v)}} style={{cursor: 'pointer'}} className="device-box-select-row">
                                    {
                                        headers.map((g, o) => {
                                            if(g.vName === 'Error') {
                                                let err = LCSS.logCodes.find(e => e.code === v.deviceData.Error);
                                                let dtip = "Fetching errors...";
                                                if(err) {
                                                    dtip = err.name + "<br/>" + err.description;
                                                }
                                                return (
                                                    <td key={"DEVICECOL_" + o} data-tip={dtip} className={cls}>
                                                        {FormatDataField({...v.deviceData, ...v.slaveData}[g.vName], g)}
                                                    </td>
                                                );    
                                            }
                                            return <td key={"DEVICECOL_" + o} className={cls}>{FormatDataField({...v.deviceData, ...v.slaveData}[g.vName], g)}</td>;
                                        })
                                    }
                                </tr>);
                            })
                        }
                    </tbody>
                </table>
                <ReactTooltip 
                    place='top'
                    effect='solid' 
                    multiline={true}
                    />
            </div>
        )
    }

    render () {
        
        if(this.state.goToDeviceDetails)
            return <Redirect push to={"/device?d=" + LCSS.selectedDeviceID} />;

        // Show admin view
        return this.adminView();
    }
}