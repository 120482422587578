import { createHash } from "crypto";
import React from "react";
import WSController, { ClientWSCommands } from "../Components/WSController";
import LCSS, { UserLevel } from "./LCSS";

/**
 * @brief Note for settings
 * @param {{text: string}} text 
 */
const SettingsNote = (props) => {
    return (
        <p style={{fontSize: "0.6em"}}>
            {props.text}
        </p>
    );
}

export default class SettingsView extends React.Component {

    state = {
        // These settings will be overwritten by HMS.settings
        settings: {
            wsAddress: "wss://lorawan-demo.softrain.fi:4351",
            loginAddress: "https://lorawan-demo.softrain.fi:4353"
        },
        changed: {
            wsAddress: false,
            loginAddress: false
        },
        /**
         * @type {{id: number, name: string}[]}
         */
        organizations: [],

        /**
         * @type {{id: number, username: string, displayName: string, organizationId: number, organization: number, level: number}[]}
         */
        users: [],

        randomFirmwareId: null,

        newOrgInfo: {
            name: ""
        },

        newUserInfo: {
            username: "",
            displayName: "",
            password: "",
            organization: 1,
            level: 1
        },

        firmware: {
            id: "",
            device: "",
            name: "",
            major: "0",
            minor: "0",
            file: null
        },
        rnd: 0
    }

    constructor (props) {
        super(props);

        this.state.settings = LCSS.settings;

        // Bindings
        this.onFieldChange = this.onFieldChange.bind(this);
        this.saveSettings = this.saveSettings.bind(this);
        this.settingFieldChanged = this.settingFieldChanged.bind(this);
        this.createUser = this.createUser.bind(this);
        this.createOrganization = this.createOrganization.bind(this);
        this.createFirmwareUpdate = this.createFirmwareUpdate.bind(this);
        this.firmwareFileChanged = this.firmwareFileChanged.bind(this);
        this.createOrganizationBox = this.createOrganizationBox.bind(this);
        this.createUserBox = this.createUserBox.bind(this);
        this.createFirmwareBox = this.createFirmwareBox.bind(this);
        this.firmwareListBox = this.firmwareListBox.bind(this);
        this.usersListBox = this.usersListBox.bind(this);
        this.organizationsListBox = this.organizationsListBox.bind(this);

    }

    componentDidMount () {
        LCSS.fetchOrganizations((orgs) => {
            if(orgs !== undefined) {
                this.setState({organizations: orgs});
            }
            else {
                // TODO: Orgs not found
            }
        });

        LCSS.fetchUsers((users) => {
            if(users !== undefined) {
                this.setState({users: users});
            }
            else {
                // TODO: Orgs not found
            }
        });

    }

    /**
     * @brief Creates an user
     */
    createUser () {
        WSController.request({
            cmd: ClientWSCommands.NEWUSER,
            username: this.state.newUserInfo.username,
            level: this.state.newUserInfo.level,
            displayName: this.state.newUserInfo.displayName,
            organizationId: this.state.newUserInfo.organization,
            password: this.state.newUserInfo.password
        }, (data) => {
            if(data.status) {
                alert("New user created");
                LCSS.fetchUsers((users) => {
                    if(users !== undefined) {
                        this.setState({users: users});
                    }
                    else {
                        // TODO: Orgs not found
                    }
                });
            }
            else {
                alert("Failed to create user");
            }
        });
    }

    /**
     * @brief Creates an organization
     */
    createOrganization () {
        WSController.request({
            cmd: ClientWSCommands.NEWORG,
            name: this.state.newOrgInfo.name
        }, (data) => {
            if(data.status) {
                alert("New organization created");
                LCSS.fetchOrganizations((orgs) => {
                    if(orgs !== undefined) {
                        this.setState({organizations: orgs});
                    }
                    else {
                        // TODO: Orgs not found
                    }
                });
            }
            else {
                alert("Failed to create organization");
            }
        });
    }

    /**
     * @brief Creates an firmware update
     */
    createFirmwareUpdate () {
        const formData = new FormData();
        formData.append("id", this.state.firmware.id);
        formData.append("device", this.state.firmware.device);
        formData.append("name", this.state.firmware.name);
        formData.append("major", this.state.firmware.major);
        formData.append("minor", this.state.firmware.minor);
        formData.append("file", this.state.firmware.file);


        fetch("https://" + window.location.host.split(":")[0] + ":" + "4355/newupdate", {
            method: 'POST',
            body: formData
        }).then((resp) => {
            return resp.json();
        }).then((jsn) => {
            if(jsn.status) {
                alert("New firmware uploaded");
            }
            else {
                alert("Failed to upload firmware");
            }
            LCSS.getFirmwares();
            this.setState({rnd: Math.random()});
        }).catch((reason) => {
            console.error("Failed to add a firmware update");
        })
    }

    /**
     * @brief On text field change
     * @param {string} field 
     * @param {Event} ev 
     */
    onFieldChange (field, ev) {
        let v = this.state.settings;
        v[field] = ev.target.value;
        this.setState({settings: v});
    }

    /**
     * @brief Saves modified settings
     */
    saveSettings () {
        try {
            let oldSettings = localStorage.getItem("HMS-SETTINGS");
            localStorage.setItem("HMS-SETTINGS", JSON.stringify(this.state.settings));
            console.log("Settings changed");
            if(oldSettings !== null) {
                // Check if important settings were changed that require page refresh
                let os = JSON.parse(oldSettings);
                if(os.wsAddress !== this.state.settings.wsAddress) {
                    console.log("wsAddress changed - refresh required");
                    this.setState({changed: { ...this.state.changed, ...{wsAddress: true} }});
                }
            }
        }
        catch (e) {
            console.warn("Could not save settings");
        }
    }

    /**
     * 
     * @param {Event} ev 
     */
    firmwareFileChanged (ev) {
        this.setState({firmware: {
            ...this.state.firmware,
            ...{
                file: ev.target.files[0]
            }
        }});
    }

    /**
     * @brief Callback for setting field change
     * @param {string} form 
     * @param {string} field 
     * @param {Event} ev
     */
    settingFieldChanged (form, field, ev) {

        if(form === 'newOrg') {
            let st = this.state.newOrgInfo;
            st[field] = ev.target.value;
            this.setState({newOrgInfo: st});
        }
        else if(form === 'newUser') {
            let st = this.state.newUserInfo;
            st[field] = ev.target.value;
            this.setState({newUserInfo: st});
        }
        else if(form === 'firmware') {
            let st = this.state.firmware;
            st[field] = ev.target.value;
            this.setState({firmware: st});
        }
    }

    /**
     * @brief Create organization box
     */
    createOrganizationBox () {
        return <div className="dyn-box">
            <h2 style={{borderBottom: '1px solid rgba(0, 0, 0, 0.4)'}}>Add a new organization</h2>
            <table>
                <tbody>
                    <tr>
                        <td>Organization name</td>
                        <td>
                            <input type='text' value={this.state.newOrgInfo.name} onChange={(ev) => this.settingFieldChanged('newOrg', 'name', ev)} />
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={2}><input type='submit' value="Send" onClick={this.createOrganization} /></td>
                    </tr>
                </tbody>
            </table>
        </div>
    }

    /**
     * @brief Create user box
     */
    createUserBox () {
        return <div className="dyn-box">
            <h2 style={{borderBottom: '1px solid rgba(0, 0, 0, 0.4)'}}>Add a new user</h2>
            <table>
                <tbody>
                    <tr>
                        <td>Username</td><td>
                            <input type='text' value={this.state.newUserInfo.username} onChange={(ev) => this.settingFieldChanged('newUser', 'username', ev)} />
                        </td>
                    </tr>
                    <tr>
                        <td>Displayed name</td>
                        <td>
                            <input type='text' value={this.state.newUserInfo.displayName} onChange={(ev) => this.settingFieldChanged('newUser', 'displayName', ev)} />
                        </td>
                    </tr>
                    <tr>
                        <td>Password</td>
                        <td>
                            <input type='password' value={this.state.newUserInfo.password} onChange={(ev) => this.settingFieldChanged('newUser', 'password', ev)} />
                        </td>
                    </tr>
                    <tr>
                        <td>Organization</td>
                        <td>
                            <select value={this.state.newUserInfo.organization} onChange={(ev) => this.settingFieldChanged('newUser', 'organization', ev)}>
                                {
                                    this.state.organizations.map((v, i) => {
                                        return <option key={"nu-org-" + i} value={v.id}>{v.name}</option>
                                    })
                                }
                            </select>
                        </td>
                    </tr>
                    <tr>
                        <td>User level</td>
                        <td>
                            <select  value={this.state.newUserInfo.level} onChange={(ev) => this.settingFieldChanged('newUser', 'level', ev)}>
                                {
                                    Object.keys(UserLevel).map((k, i) => {
                                        return <option key={'nu-userlevel-' + i} value={UserLevel[k]}>{k}</option>
                                    })
                                }
                            </select>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={2}><input type='submit' value="Send" onClick={this.createUser} /></td>
                    </tr>
                </tbody>
            </table>
        </div>
    }

    /**
     * 
     * @brief Create firmware box
     */
    createFirmwareBox () {
        return <div className="dyn-box">
            <h2 style={{borderBottom: '1px solid rgba(0, 0, 0, 0.4)'}}>Firmware update</h2>
            <p 
                style={{fontSize: '1em', color: '#777', cursor: this.state.randomFirmwareId === null ? 'pointer' : 'text', fontFamily: 'monospace'}}
                onClick={() => {
                    if(this.state.randomFirmwareId === null)
                        this.setState({randomFirmwareId: createHash("md5").update(Date.now() + '123').digest("hex")});
                }}
            >
                {
                    this.state.randomFirmwareId === null &&
                    <span>Click to generate firmware ID</span>
                }
                {
                    this.state.randomFirmwareId !== null &&
                    <span>{this.state.randomFirmwareId}</span>
                }
            </p>
            <table>
                <tbody>
                    <tr>
                        <td>Update ID</td>
                        <td>
                            <input type='text' value={this.state.firmware.id} onChange={(ev) => this.settingFieldChanged('firmware', 'id', ev)} />
                            <span style={{marginLeft: 3}}>
                                <span style={{color: '#888', fontSize: '0.7em'}}>Random MD5 hash. <b>MUST</b> match with the FIRMWARE_ID</span>
                            </span>
                        </td>
                    </tr>
                    <tr>
                        <td>Device type</td>
                        <td>
                            <select onChange={(ev) => this.settingFieldChanged('firmware', 'device', ev)}>
                                <option value={'LCDIO'}>LCDIO</option>
                                <option value={'LC'}>LC</option>
                            </select>
                        </td>
                    </tr>
                    <tr>
                        <td>Update name</td>
                        <td>
                            <input type='text' value={this.state.firmware.name} onChange={(ev) => this.settingFieldChanged('firmware', 'name', ev)} />
                        </td>
                    </tr>
                    <tr>
                        <td>Major version</td>
                        <td>
                            <input type='number' value={this.state.firmware.major} onChange={(ev) => this.settingFieldChanged('firmware', 'major', ev)} />
                        </td>
                    </tr>
                    <tr>
                        <td>Minor version</td>
                        <td>
                            <input type='number' value={this.state.firmware.minor} onChange={(ev) => this.settingFieldChanged('firmware', 'minor', ev)} />
                        </td>
                    </tr>
                    <tr>
                        <td>Firmware file</td>
                        <td>
                            <input type={'file'} accept={'.bin'} onChange={this.firmwareFileChanged} />
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={2}><input type='submit' value="Send" onClick={this.createFirmwareUpdate} /></td>
                    </tr>
                </tbody>
            </table>
        </div>
    }

    /**
     * @brief Firmware list box
     */
    firmwareListBox () {
        return <div className="dyn-box">
            <h2 style={{borderBottom: '1px solid rgba(0, 0, 0, 0.4)'}}>Firmwares</h2>
            <table>
                <tbody>
                    <tr>
                        <th>ID</th><th>Type</th><th>Name</th><th>Version</th>
                    </tr>
                    {
                        LCSS.firmwares.images.map((e, i) => {
                            return <tr key={'fw-' + i}>
                                <td style={{fontFamily: "monospace"}}>{e.id}</td><td>{e.device||'LCDIO'}</td><td>{e.name}</td><td>{e.major}.{e.minor}</td>
                            </tr>
                        })
                    }
                </tbody>
            </table>
        </div>
    }

    /**
     * 
     * @brief Users list box
     */
    usersListBox () {
        return <div className="dyn-box">
            <h2 style={{borderBottom: '1px solid rgba(0, 0, 0, 0.4)'}}>Users</h2>
            <table>
                <tbody>
                    <tr>
                        <th>Username</th><th>Display name</th><th>Organization</th><th>Level</th>
                    </tr>
                    {
                        this.state.users.map((e, i) => {
                            return <tr key={'userlist-' + i}>
                                <td>
                                    {e.username}
                                </td>
                                <td>
                                    {e.displayName}
                                </td>
                                <td>
                                    {e.organization}
                                </td>
                                <td>
                                    {Object.keys(UserLevel).find(c => UserLevel[c] === e.level)}
                                </td>
                            </tr>;
                        })
                    }
                </tbody>
            </table>
        </div>
    }

    /**
     * @brief Organizations list box
     */
    organizationsListBox () {
        return <div className="dyn-box">
            <h2 style={{borderBottom: '1px solid rgba(0, 0, 0, 0.4)'}}>Organizations</h2>
            <table>
                <tbody>
                    <tr>
                        <th>Organization</th>
                    </tr>
                    {
                        this.state.organizations.map((e, i) => {
                            return <tr key={'orglist-' + i}>
                                <td>
                                    {e.name}
                                </td>
                            </tr>;
                        })
                    }
                </tbody>
            </table>
        </div>
    }

    

    render () {

        let mobilePortrait = window.innerWidth < 768;

        return (
            <div className="dyn-box-view">
                {
                    !mobilePortrait &&
                    <div style={{flexDirection: 'row', flex: 1, display: 'flex'}}>
                        <div style={{flexDirection: 'column', flex: 1, display: 'flex'}}>
                            <this.createOrganizationBox />
                            <this.createFirmwareBox />
                            <this.firmwareListBox />
                        </div>
                        <div style={{flexDirection: 'column', flex: 1, display: 'flex'}}>
                            <this.createUserBox />
                            <this.usersListBox />
                            <this.organizationsListBox />

                        </div>  

                            
                    </div>
                }
                {
                    mobilePortrait &&
                    <div style={{flexDirection: 'row', flexWrap: 'wrap', flex: 1, display: 'flex'}}>

                        <this.createOrganizationBox />
                        <this.createUserBox />
                        <this.createFirmwareBox />
                        <this.firmwareListBox />
                        <this.usersListBox />
                        <this.organizationsListBox />
                    </div>
                }
            </div>
        );
    }

}